import * as React from 'react';
import Meta from 'styleguide/components/Meta/Meta';
import Footer from 'styleguide/components/Footer/Footer';
import TitleContainer from 'bundles/App/pages/Account/TitleContainer/TitleContainer';
import Link from 'styleguide/components/Links/Link';
import {
  blogPath,
  careersPath,
  contactUsPath,
  helpCenterPath,
  hoursAndLocationPath,
  ourStoryPath,
  products,
  servicePath,
  siteMapPath,
  uploadPath,
} from 'bundles/App/routes';
import AppContext from 'contexts/AppContext/AppContext';
import A from 'styleguide/components/Links/A';
import { CmsCategoryPage, CmsProductPage, CmsServicePage } from 'contexts/AppContext/types';

const SiteMap = () => {
  const appContext = React.useContext(AppContext);
  const cmsProductPages: CmsProductPage[] = appContext.store.cmsPages.filter(
    (elem: CmsProductPage | CmsCategoryPage | CmsServicePage) =>
      elem.type === 'Comfy::Cms::Page::Product' && !elem.fullPath.includes('/landing'),
  ) as CmsProductPage[];
  const cmsCategoryPages: CmsCategoryPage[] = appContext.store.cmsPages.filter(
    (elem: CmsProductPage | CmsCategoryPage | CmsServicePage) =>
      elem.type === 'Comfy::Cms::Page::Category' && elem.fullPath !== '/landing',
  ) as CmsCategoryPage[];

  return (
    <>
      <Meta
        title="Site Map | Printivity"
        description={
          'Browse a listing of our best online printing services, visit Printivity today! ' +
          'Excellent customer service, low prices, quick turnarounds, and a 100% satisfaction guarantee.'
        }
        canonical="https://www.printivity.com/site-map"
      />
      <TitleContainer title="Sitemap" />
      <div className="mx-auto mt-2 max-w-base px-1">
        <div>
          <div className="pb-2 pt-4">
            <Link to={products} color="black" underline="always">
              <h2 className="font-hvBold text-xl leading-5 tracking-tight">Products</h2>
            </Link>
          </div>
          <div className="flex flex-col md:flex-row md:flex-wrap">
            {cmsProductPages.map((t: CmsProductPage, i: number) => (
              <div className="md:basis-1/2 lg:basis-1/3" key={i}>
                <Link to={t.fullPath} color="blue" underline="always">
                  <div key={i}>{t.label}</div>
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className="pb-2 pt-4">
            <h2 className="font-hvBold text-xl leading-5 tracking-tight">Categories</h2>
          </div>
          <div className="flex flex-col md:flex-row md:flex-wrap">
            {cmsCategoryPages.map((t: CmsCategoryPage, i: number) => (
              <div className="md:basis-1/2 lg:basis-1/3" key={i}>
                <Link to={t.fullPath} color="blue" underline="always">
                  <div key={i}>{t.label}</div>
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className="pb-2 pt-4">
            <h2 className="font-hvBold text-xl leading-5 tracking-tight">Services</h2>
          </div>
          <div className="flex flex-col md:flex-row md:flex-wrap">
            <div className="md:basis-1/2 lg:basis-1/3">
              <Link to={servicePath.rushCriticalPath} color="blue" underline="always">
                <div>Rush Critical</div>
              </Link>
            </div>
            <div className="md:basis-1/2 lg:basis-1/3">
              <Link to={servicePath.franchiseSolutionsPath} color="blue" underline="always">
                <div>Franchise Solutions</div>
              </Link>
            </div>
            <div className="md:basis-1/2 lg:basis-1/3">
              <Link to={servicePath.insightsPath} color="blue" underline="always">
                <div>Insights</div>
              </Link>
            </div>
            <div className="md:basis-1/2 lg:basis-1/3">
              <Link to={servicePath.boxDesignPath} color="blue" underline="always">
                <div>Box Design</div>
              </Link>
            </div>
            <div className="md:basis-1/2 lg:basis-1/3">
              <Link to={servicePath.designServices} color="blue" underline="always">
                <div>Graphic Design Services</div>
              </Link>
            </div>
          </div>
        </div>
        <div>
          <div className="pb-2 pt-4">
            <h2 className="font-hvBold text-xl leading-5 tracking-tight">Other Pages</h2>
          </div>
          <div className="flex flex-col md:flex-row md:flex-wrap">
            <div className="md:basis-1/2 lg:basis-1/3">
              <Link to={ourStoryPath} color="blue" underline="always">
                <div>Our Story</div>
              </Link>
            </div>
            <div className="md:basis-1/2 lg:basis-1/3">
              <Link to={careersPath} color="blue" underline="always">
                <div>Careers</div>
              </Link>
            </div>
            <div className="md:basis-1/2 lg:basis-1/3">
              <Link to={siteMapPath} color="blue" underline="always">
                <div>Sitemap</div>
              </Link>
            </div>
            <div className="md:basis-1/2 lg:basis-1/3">
              <Link to={helpCenterPath} color="blue" underline="always">
                <div>Help Center</div>
              </Link>
            </div>
            <div className="md:basis-1/2 lg:basis-1/3">
              <Link to={hoursAndLocationPath} color="blue" underline="always">
                <div>Hours and Location</div>
              </Link>
            </div>
            <div className="md:basis-1/2 lg:basis-1/3">
              <Link to={contactUsPath} color="blue" underline="always">
                <div>Contact Us</div>
              </Link>
            </div>
            <div className="md:basis-1/2 lg:basis-1/3">
              <A href={blogPath} color="blue" underline="always">
                <div>Insights</div>
              </A>
            </div>
            <div className="md:basis-1/2 lg:basis-1/3">
              <Link to={uploadPath} color="blue" underline="always">
                <div>Guest File Upload</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default SiteMap;
